import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <Content>
      <h1>davidp.io</h1>
      <p>Still under construction...</p>
    </Content>
  </Layout>
)

export default NotFoundPage
